import { default as indexE0eU27vCdlMeta } from "/opt/render/project/src/apps/website/pages/index.vue?macro=true";
export default [
  {
    name: indexE0eU27vCdlMeta?.name ?? "index___bg",
    path: indexE0eU27vCdlMeta?.path ?? "/",
    meta: indexE0eU27vCdlMeta || {},
    alias: indexE0eU27vCdlMeta?.alias || [],
    redirect: indexE0eU27vCdlMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexE0eU27vCdlMeta?.name ?? "index___en",
    path: indexE0eU27vCdlMeta?.path ?? "/en",
    meta: indexE0eU27vCdlMeta || {},
    alias: indexE0eU27vCdlMeta?.alias || [],
    redirect: indexE0eU27vCdlMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/index.vue").then(m => m.default || m)
  }
]