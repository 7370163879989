export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"apple-mobile-web-app-title","content":"eFirma"},{"name":"application-name","content":"eFirma"},{"name":"msapplication-TileColor","content":"#2d89ef"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"preload","href":"/fonts/inter/Inter-Medium.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/inter/Inter-Regular.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/inter/Inter-Bold.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/inter/Inter-SemiBold.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/inter/Inter-Italic.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/inter/Inter-BoldItalic.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#2563eb"}],"style":[],"script":[],"noscript":[],"title":"eFirma.bg"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false